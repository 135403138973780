@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import "~@angular/material/theming";
@import '@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';

@import './theme-colors.scss';

//
@include mat-core();
// Colors
$gipi-primary: mat-palette($gipi-palette-secondary, 500, 500);
$gipi-accent: mat-palette($gipi-palette-primary, 500, 500);
$gipi-danger: mat-palette($gipi-palette-danger, 500, 500);
$gipi-theme: mat-light-theme($gipi-primary, $gipi-accent, $gipi-danger);
@include angular-material-theme($gipi-theme);
@include mat-datetimepicker-theme($gipi-theme);
//
// Font
$gipi-typography : mat-typography-config($font-family: $gipi-font-family);
@include angular-material-typography($gipi-typography);

* {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
    list-style: none;
}

:root,
html {
    font-family: $gipi-font-family !important;
    font-size: 62.5%;
}

body {
    font-family: $gipi-font-family !important;
    font-size: 1.4rem;
    font-weight: 400;
    color: $gipi-common-black-100;
}

.mat-snack-bar-container {
    background: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.mat-table {
    font-family: $gipi-font-family !important;
    font-size: 1.4rem !important;
}

.mat-header-cell {
    font-family: $gipi-font-family !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    color: $gipi-primary-500 !important;
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
[mat-sort-header].cdk-program-focused .mat-sort-header-container {
    border: none !important;
}

.mat-sort-header-arrow {
    color: $gipi-primary-500 !important;
}

.mat-header-row {
    height: 4rem !important;
    border-bottom: 1px solid $gipi-primary-500 !important;
}

.mat-row {
    // height: 5rem !important;
    cursor: pointer !important;

    &:hover {
        background-color: $gipi-common-gray-50 !important;
    }
}

.mat-column-expandedRowColumn {
    padding: 0 !important;
}

.mat-cell {
    font-size: 1.2rem !important;
    font-weight: 500 !important;

    &:not(.mat-column-expandedRowColumn) {
        text-transform: uppercase !important;
    }
}

.mat-cell:first-of-type,
.mat-header-cell:first-of-type,
.mat-footer-cell:first-of-type {

    &.cdk-column-launch {
        padding-left: 0 !important;
    }
}

.mat-footer-cell {
    font-size: 1.4rem !important;
}

.mat-paginator {
    background: transparent !important;
}

.mat-drawer-content {
    overflow: hidden !important;
}

.material-icons.md-18 {
    font-size: 1.8rem;
}

.material-icons.md-24 {
    font-size: 2.4rem;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

.material-icons-outlined.md-18 {
    font-size: 18px;
}

.material-icons-outlined.md-24 {
    font-size: 24px;
}

.material-icons-outlined.md-36 {
    font-size: 36px;
}

.material-icons-outlined.md-40 {
    font-size: 40px;
}

.material-icons-outlined.md-48 {
    font-size: 48px;
}

.material-icons-outlined.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons-outlined.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

/* mat-paginator*/
.mat-paginator-range-actions {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px !important;
}

.mat-paginator-container {
    justify-content: center !important;
}

.mat-paginator-navigation-first {
    order: 1 !important;
}

.mat-paginator-navigation-previous {
    order: 2 !important;
}

.mat-paginator-range-label {
    order: 3 !important;
}

.mat-paginator-navigation-next {
    order: 4 !important;
}

.mat-paginator-navigation-last {
    order: 5 !important;
}

.mat-paginator-range-label {
    margin: 5px !important;
    display: block !important;
}

.mat-paginator-page-size {
    display: none !important;
}

.mde-popover-panel {
    margin-top: 2px !important;
}

.mde-popover-direction-arrow {
    left: 8px !important;
}

.mat-dialog-container {
    border-radius: 6px !important;
    padding: 0 !important;
}

.mat-dialog-content {
    font-family: $gipi-font-family !important;
    font-size: 14px !important;
    padding: 0 !important;
    margin: 0 !important;
    max-height: auto !important;
    flex: 1 !important;
}

.mat-dialog-title {
    font-family: $gipi-font-family !important;
    font-weight: 600 !important;
    padding: 0 !important;
    margin: 0 !important;
}

.mat-dialog-actions {
    padding: 0 !important;
    min-height: auto !important;
    margin: 0 !important;
}

.dialog-panel {
    overflow-y: auto !important;
}

textarea {
    font-size: 13px !important;
    color: #696969 !important;
}

button:disabled {
    user-select: none !important;
    cursor: not-allowed !important;
}

.mat-button-disabled:disabled {
    cursor: not-allowed !important;
    user-select: none !important;
    pointer-events: none !important;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-corner {
    background: #f1f4f9;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(51, 51, 51, 0.4);
    border: 2px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-track {
    background: #f1f4f9;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
}

// Icons
mat-icon {
    user-select: none !important;
}

// Datepicker

.mat-datepicker-toggle {
    color: #696969 !important;

    &,
    .mat-icon-button,
    .mat-icon-button .mat-button-wrapper,
    .mat-icon-button .mat-button-wrapper>svg {
        width: 2rem !important;
        height: 2rem !important;
        line-height: 2rem !important;
    }

    .mat-icon-button .mat-button-wrapper {
        display: block !important;
    }

    >button {

        .mat-button-ripple,
        .mat-button-focus-overlay {
            display: none !important;
        }
    }
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
    color: $gipi-common-white-100 !important;
}

.mat-calendar-body-selected {
    background-color: $gipi-primary-500 !important;
    color: $gipi-common-white-100;
    border-radius: 100%;
}

.mat-calendar-arrow {
    border-top-color: $gipi-common-white-100;
}

.mat-calendar-table-header {
    color: $gipi-common-black-300;
}

.mat-calendar-body-label {
    color: $gipi-common-black-300;
}

.mat-calendar-header {
    background-color: $gipi-primary-500;
    color: $gipi-common-white-100;
    padding: .9rem !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}

.mat-calendar-table-header th {
    padding: .9rem !important;
}

.mat-calendar-table-header-divider {
    padding: 0 !important;
}

.mat-calendar-controls {
    margin: 0 !important;
}

.mat-calendar-content {
    padding: .9rem !important;
}

.mat-hint {
    color: $gipi-common-black-300 !important;
    font-family: $gipi-font-family !important;
    font-size: 11px;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: $gipi-primary-500;
}

.mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px $gipi-common-white-100;
}

// Mat slide toggle
.mat-slide-toggle .mat-slide-toggle-bar {
    background-color: $gipi-common-gray-700;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: $gipi-common-white-100;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $gipi-common-gray-700;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $gipi-common-gray-900;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: $gipi-common-gray-900;
}

.mat-slide-toggle-content {
    font-family: $gipi-font-family;
    font-size: 1.4rem;
    font-weight: 600;
    color: $gipi-common-gray-700;
}

// Timepicker
.inner-dot,
.outer-dot,
.mat-toolbar.mat-toolbar-single-row.mat-primary {
    background: $gipi-primary-500 !important;
    color: $gipi-common-white-100 !important;
}

.number:not(.disabled).selected {
    color: $gipi-common-white-100 !important;
}

.mat-button.mat-button-base.mat-primary {
    margin: 0 1rem 1rem 0 !important;
    width: 10rem !important;

    &:first-child {
        border: 1px solid $gipi-common-black-300 !important;
        color: $gipi-common-black-300 !important;
        background: transparent !important;

        &:hover {
            background-color: $gipi-common-gray-100 !important;
        }
    }

    &:not(:first-child) {
        background: $gipi-primary-500 !important;
        color: $gipi-common-white-100 !important;

        &:hover {
            background-color: $gipi-primary-600 !important;
        }
    }

}