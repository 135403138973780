// Variables
// Globals
$gipi-top-nav-height: 66px;
$gipi-footer-height: 8px;

// Se alterar o $gipi-top-nav-height e o $gipi-footer-height, tem que alterar aqui tbm.
// Cálculo é: calc(100vh - $gipi-top-nav-height - $gipi-footer-height);
$gipi-sidenav-container-height: calc(100vh - 66px - 8px);

$gipi-sidenav-width-large: 248px;
$gipi-sidenav-width-small: 76px;
$gipi-sidenav-padding: .8rem 1.2rem;

$gipi-zindex: 1;

// Breakpoints baseados em https://github.com/angular/flex-layout/wiki/Responsive-API
// Extra small screen / phone
$gipi-screen-xs: 420px;

// Small screen / tablet
$gipi-screen-sm: 600px;

// Medium screen / desktop
$gipi-screen-md: 768px;

// Large screen / wide desktop
$gipi-screen-lg: 1366px;

// Extra large screen / full hd
$gipi-screen-xl: 1920px;

// Paddings
$padding-xs: 8px; // small items
$padding-sm: 12px; // Form controls and items
$padding-md: 16px; // small containers and buttons
$padding-lg: 64px; // containers

// Margins
$margin-xs: 8px; // small items
$margin-sm: 12px; // Form controls and items
$margin-md: 16px; // small containers and buttons
$margin-lg: 64px; // containers

// Fonte
// $font-family: "Lato, sans-serif";
$gipi-font-family: "Nunito", sans-serif;
// ---------------------------------------------

// Cores provisórias ---------------------------
$gipi-input-border-provisory: #0000001f;
$gipi-input-color-provisory: #696969;
// ---------------------------------------------
// Primary
$gipi-primary-50 : #fceaec;
$gipi-primary-100 : #f5bfc3;
$gipi-primary-200 : #f1a0a6;
$gipi-primary-300 : #ea757e;
$gipi-primary-400 : #e65a65;
$gipi-primary-500 : #e0313e; // Is default
$gipi-primary-600 : #cc2d38;
$gipi-primary-700 : #9f232c;
$gipi-primary-800 : #7b1b22;
$gipi-primary-900 : #5e151a;
// Secondary
$gipi-secondary-50 : #f5f5f6;
$gipi-secondary-100 : #e0e1e2;
$gipi-secondary-200 : #d1d2d4;
$gipi-secondary-300 : #bbbdc1;
$gipi-secondary-400 : #aeb1b5;
$gipi-secondary-500 : #9a9da2; // Is default
$gipi-secondary-600 : #8c8f93;
$gipi-secondary-700 : #6d6f73;
$gipi-secondary-800 : #555659;
$gipi-secondary-900 : #414244;
// Success
$gipi-success-100 : #bbdcb6;
$gipi-success-300 : #6db362;
$gipi-success-400 : #51a543;
$gipi-success-500 : #258e14; // Is default
$gipi-success-600 : #228112;
$gipi-success-700 : #1a650e;
$gipi-success-900 : #103c08;
// Information
$gipi-info-100 : #b0d8ef;
$gipi-info-300 : #54aadb;
$gipi-info-400 : #3399d5;
$gipi-info-500 : #0080ca; // Is default
$gipi-info-600 : #0074b8;
$gipi-info-700 : #005b8f;
$gipi-info-900 : #003655;
// Warning
$gipi-warning-100 : #f2e2b0;
$gipi-warning-300 : #e4bf54;
$gipi-warning-400 : #deb333;
$gipi-warning-500 : #d6a000; // Is default
$gipi-warning-600 : #c39200;
$gipi-warning-700 : #987200;
$gipi-warning-900 : #5a4300;
// Danger
$gipi-danger-100 : #f1c4b6;
$gipi-danger-300 : #e07f62;
$gipi-danger-400 : #da6643;
$gipi-danger-500 : #d14014; // Is default
$gipi-danger-600 : #be3a12;
$gipi-danger-700 : #942d0e;
$gipi-danger-900 : #581b08;
// White
$gipi-common-white-100 : #ffffff; // Is default
$gipi-common-white-200 : #fcfcfc;
$gipi-common-white-300 : #f5f5f5;
$gipi-common-white-400 : #f0f0f0;
// Black
$gipi-common-black-100 : #131313; // Is default
$gipi-common-black-200 : #262626;
$gipi-common-black-300 : #595959;
$gipi-common-black-400 : #bfbfbf;
// Gray
$gipi-common-gray-50 : #f5f5f6;
$gipi-common-gray-100 : #e0e1e2;
$gipi-common-gray-200 : #d1d2d4;
$gipi-common-gray-300 : #bbbdc1;
$gipi-common-gray-400 : #aeb1b5;
$gipi-common-gray-500 : #9a9da2; // Is default
$gipi-common-gray-600 : #8c8f93;
$gipi-common-gray-700 : #6d6f73;
$gipi-common-gray-800 : #555659;
$gipi-common-gray-900 : #414244;
// Pastels - No default color
$gipi-common-pastels-primary : #f3e7e7;
$gipi-common-pastels-secondary : #e7ebee;
$gipi-common-pastels-success : #e9f4e8;
$gipi-common-pastels-info : #e6f2fa;
$gipi-common-pastels-warning : #fbf6e6;
$gipi-common-pastels-danger : #faece8;
//

$gipi-palette-primary: (
    50 : $gipi-primary-50,
    100 : $gipi-primary-100,
    200 : $gipi-primary-200,
    300 : $gipi-primary-300,
    400 : $gipi-primary-400,
    500 : $gipi-primary-500,
    600 : $gipi-primary-600,
    700 : $gipi-primary-700,
    800 : $gipi-primary-800,
    900 : $gipi-primary-900,
    contrast: ( //
        50 : $gipi-common-black-100,
        100 : $gipi-common-black-100,
        200 : $gipi-common-black-100,
        300 : $gipi-common-black-100,
        400 : $gipi-common-black-100,
        500 : $gipi-common-white-100,
        600 : $gipi-common-white-100,
        700 : $gipi-common-white-100,
        800 : $gipi-common-white-100,
        900 : $gipi-common-white-100,
    )
);
//
$gipi-palette-secondary: (
    100 : $gipi-secondary-100,
    300 : $gipi-secondary-300,
    400 : $gipi-secondary-400,
    500 : $gipi-secondary-500,
    600 : $gipi-secondary-600,
    700 : $gipi-secondary-700,
    900 : $gipi-secondary-900,
    contrast: ( //
        50 : $gipi-common-black-100,
        100 : $gipi-common-black-100,
        200 : $gipi-common-black-100,
        300 : $gipi-common-black-100,
        400 : $gipi-common-black-100,
        500 : $gipi-common-black-100,
        600 : $gipi-common-black-100,
        700 : $gipi-common-white-100,
        800 : $gipi-common-white-100,
        900 : $gipi-common-white-100,
    )
);
//
$gipi-palette-success: (
    100 : $gipi-success-100,
    300 : $gipi-success-300,
    400 : $gipi-success-400,
    500 : $gipi-success-500,
    600 : $gipi-success-600,
    700 : $gipi-success-700,
    900 : $gipi-success-900,
    contrast: ( //
        100 : $gipi-common-black-100,
        300 : $gipi-common-black-100,
        400 : $gipi-common-black-100,
        500 : $gipi-common-white-100,
        600 : $gipi-common-white-100,
        700 : $gipi-common-white-100,
        900 : $gipi-common-white-100,
    )
);
//
$gipi-palette-info: (
    100 : $gipi-info-100,
    300 : $gipi-info-300,
    400 : $gipi-info-400,
    500 : $gipi-info-500,
    600 : $gipi-info-600,
    700 : $gipi-info-700,
    900 : $gipi-info-900,
    contrast: ( //
        100 : $gipi-common-black-100,
        300 : $gipi-common-black-100,
        400 : $gipi-common-black-100,
        500 : $gipi-common-white-100,
        600 : $gipi-common-white-100,
        700 : $gipi-common-white-100,
        900 : $gipi-common-white-100,
    )
);
//
$gipi-palette-warning: (
    100 : $gipi-warning-100,
    300 : $gipi-warning-300,
    400 : $gipi-warning-400,
    500 : $gipi-warning-500,
    600 : $gipi-warning-600,
    700 : $gipi-warning-700,
    900 : $gipi-warning-900,
    contrast: ( //
        100 : $gipi-common-black-100,
        300 : $gipi-common-black-100,
        400 : $gipi-common-black-100,
        500 : $gipi-common-white-100,
        600 : $gipi-common-white-100,
        700 : $gipi-common-white-100,
        900 : $gipi-common-white-100,
    )
);
//
$gipi-palette-danger: (
    100 : $gipi-danger-100,
    300 : $gipi-danger-300,
    400 : $gipi-danger-400,
    500 : $gipi-danger-500,
    600 : $gipi-danger-600,
    700 : $gipi-danger-700,
    900 : $gipi-danger-900,
    contrast: ( //
        100 : $gipi-common-black-100,
        300 : $gipi-common-black-100,
        400 : $gipi-common-black-100,
        500 : $gipi-common-white-100,
        600 : $gipi-common-white-100,
        700 : $gipi-common-white-100,
        900 : $gipi-common-white-100,
    )
);
//
$gipi-palette-common: (
    white : ( // 100 is default
        100 : $gipi-common-white-100,
        200 : $gipi-common-white-200,
        300 : $gipi-common-white-300,
        400 : $gipi-common-white-400,
    ),
    black : ( // 100 is default
        100 : $gipi-common-black-100,
        200 : $gipi-common-black-200,
        300 : $gipi-common-black-300,
        400 : $gipi-common-black-400,
    ),
    gray : ( // 500 is default
        50 : $gipi-common-gray-50,
        100 : $gipi-common-gray-100,
        200 : $gipi-common-gray-200,
        300 : $gipi-common-gray-300,
        400 : $gipi-common-gray-400,
        500 : $gipi-common-gray-500,
        600 : $gipi-common-gray-600,
        700 : $gipi-common-gray-700,
        800 : $gipi-common-gray-800,
        900 : $gipi-common-gray-900,
    ),
    pastels : ( // No default color
        primary : $gipi-common-pastels-primary,
        secondary : $gipi-common-pastels-secondary,
        success : $gipi-common-pastels-success,
        info : $gipi-common-pastels-info,
        warning : $gipi-common-pastels-warning,
        danger : $gipi-common-pastels-danger,
    ),
);
